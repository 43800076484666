import { WebstoreDocumentKey } from "@/enums"
import { atom } from "jotai"

export const webstorePolicyUIStateAtom = atom(false)

export const webstorePolicyDocumentAtom = atom(WebstoreDocumentKey?.PAIA_MANUAL)

export type ViewedStorePolicies = {
	paiaManual: boolean
	privacyPolicy: boolean
	returnsRefundsPolicy: boolean
	deliveryPolicy: boolean
	acceptedAt: null | undefined | string
}

export const viewedStorePoliciesAtom = atom<ViewedStorePolicies>({
	paiaManual: false,
	privacyPolicy: false,
	returnsRefundsPolicy: false,
	deliveryPolicy: false,
	acceptedAt: null,
})
