import { CardProps, Product, Products } from "@/types"
import { zarConverter } from "@/utils/catalogue"

export type SortFn = (products: Product[]) => Product[]

export const sortByCreatedAtDesc: SortFn = (products: Product[]): Product[] =>
	[...products].sort((a, b) => {
		if (!a.createdAt || !b.createdAt) {
			return 0
		}

		return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
	})

export type FilterFn = (products: Product[]) => Product[]

export const getFilteredProducts = (products: Product[], filters: FilterFn[]): Product[] =>
	filters.reduce((filteredProducts, filter) => filter(filteredProducts), products)

export type BodyMiddleware<TBody> = (body: TBody) => TBody

const convertPrice = (price: number) => {
	return zarConverter(price, "RAND")
}

export const convertPriceToRand: BodyMiddleware<Product | Product[]> = (product) => {
	if (isSingleProduct(product)) {
		const productPrice = convertPrice(product.price)
		const variantsWithNewPrice = product.variants?.map((variant) => {
			const variantPrice = convertPrice(variant.price)
			return {
				...variant,
				price: variantPrice,
			}
		})

		return {
			...product,
			price: productPrice,
			variants: variantsWithNewPrice,
		}
	}

	return product.map((product) => {
		const productPrice = convertPrice(product.price)
		const variantsWithNewPrice = product.variants?.map((variant) => {
			const variantPrice = convertPrice(variant.price)
			return {
				...variant,
				price: variantPrice,
			}
		})
		return {
			...product,
			price: productPrice,
			variants: variantsWithNewPrice,
		}
	})
}

export const isSingleProduct = (input: any): input is Product => {
	return typeof input !== "object" || !Array.isArray(input)
}

/**
 * The endpoint for creating a product returns a variants field, but it's empty if no variants were specified.
 * The endpoint for getting a product does not return a variants field if no variants were specified.
 *
 * This is a slight difference and affects the cache updates when creating a product.
 * This function ensures a similar structure for both cases.
 * @param product
 * @returns
 */
export const convertProductNoVariants = (product: Product | Product[]): Product | Product[] => {
	if (isSingleProduct(product)) {
		return convert(product)
	}

	return product.map(convert)
}

const convert = (product: Product): Product => {
	if (Array.isArray(product.variants) && product.variants.length === 0) {
		product.variants = undefined
	}

	return product
}

const sort = (product: Product): Product => {
	const _product = { ...product }
	if (!_product.variants || _product.variants.length === 0) return product

	_product.variants.sort(({ priority: priorityA }, { priority: priorityB }) => {
		if (priorityA > priorityB) return 1
		if (priorityA < priorityB) return -1
		return 0
	})

	return _product
}

export const sortVariants: BodyMiddleware<Product | Product[]> = (products) => {
	if (isSingleProduct(products)) {
		return sort(products)
	}
	return products.map(sort)
}

export const mapProductsToCard = (products: Products): CardProps[] => {
	const formatCaption = (product: Product) => {
		if (!product.variants?.length) {
			return ""
		}

		const variantsCount = product.variants?.length
		return `${variantsCount} option${variantsCount > 1 ? "s" : ""}`
	}

	return products.map((product) => ({
		id: product.catalogueItemID,
		name: product.name,
		description: product.description,
		category: product.categories[0],
		caption: formatCaption(product),
		price: product.price,
		variants: (product.variants ?? []).map((variant) => {
			return {
				id: `${variant.catalogueItemID}.${variant.variantID}`,
				values: variant.variantValues,
				price: variant.price,
			}
		}),
		imageUrls: product.imageList.raw ?? [],
		createdAt: product.createdAt,
		type: "product",
		isVatApplicable: product.isVatApplicable,
	}))
}
