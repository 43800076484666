import { getStoresFromCookies, getUserInfoFromCookies } from "@/api/clientSafe"
import { Store, User } from "@/types"
import { getStaleTimeInSeconds, SESSION_EXPIRED_EVENT } from "@/utils"
import { useQuery } from "@tanstack/react-query"
import React from "react"

/**
 * 10 minutes
 */
const defaultStaleTime = getStaleTimeInSeconds(60 * 10)

export const useGetUserQuery = (staleTime: number = defaultStaleTime) => {
	const getUserQuery = useQuery({
		queryKey: ["user"],
		queryFn: () => getUserInfoFromCookies(),
		staleTime,
		retry: false,
	})

	const { data } = getUserQuery

	const owner = data?.owner
	const merchantId = (owner ?? "").split("-")[1]

	return { ...getUserQuery, merchantId }
}

export const useGetStoresQuery = (merchantId: string, staleTime: number = defaultStaleTime) => {
	const getStoreQuery = useQuery({
		queryKey: [merchantId, "stores"],
		queryFn: () => getStoresFromCookies(merchantId),
		staleTime,
		enabled: !!merchantId,
		retry: false,
	})

	const store = getStoreQuery.data?.[0]

	return { ...getStoreQuery, store }
}

export type UserDetails = {
	stores?: Store[]
	user?: User
	/**
	 * Full owner string. Example: "Merchant-1234-5678"
	 */
	owner?: string
	/**
	 * First parth of owner. Example: "Merchant-1234-5678" => "1234"
	 */
	merchantId?: string
	/**
	 * First store in user's stores array
	 */
	store?: Store
	/**
	 * No pending requests
	 */
	isDataReady: boolean
	errors: Error[]
	/**
	 * Retry all requests
	 */
	retry: () => void
}

export const useUserDetails = (): UserDetails => {
	const { data: user, merchantId, error: userError, refetch: userRefetch, isFetching: userFetching } = useGetUserQuery()

	const {
		data: stores,
		store,
		isFetching: storesFetching,
		error: storesError,
		refetch: storesRefetch,
	} = useGetStoresQuery(merchantId)

	const retry = React.useCallback(() => {
		userRefetch().then(() => storesRefetch())
	}, [userRefetch, storesRefetch])

	const storeId = store?.id
	const owner = merchantId && storeId ? `Merchant-${merchantId}-${storeId}` : undefined

	return {
		retry,
		stores,
		store,
		user,
		owner,
		merchantId,
		isDataReady: !userFetching && !storesFetching,
		errors: [userError, storesError].filter(Boolean) as Error[],
	}
}

export const useSessionTimeout = () => {
	const [expired, setExpired] = React.useState(false)

	const onExpired = () => setExpired(true)

	React.useLayoutEffect(() => {
		window.addEventListener(SESSION_EXPIRED_EVENT, onExpired)

		return () => {
			window.removeEventListener(SESSION_EXPIRED_EVENT, onExpired)
		}
	}, [])

	return expired
}
