import { BasketItem, CatalogueType } from "@/api"
import { CartItem } from "@/hooks/useCart"

export const capitalize = (value: string) => value && String(value[0]).toUpperCase() + String(value).slice(1)

export const mapCartItemsToBasketItems = (items: CartItem[]): BasketItem[] => {
	return items.flatMap((item, index) => {
		const basketItem: BasketItem = {
			name: item.name,
			pricePerUnit: item.price * 100,
			quantity: item.quantity,
			description: item.description,
			catalogueType: item.type === "product" ? CatalogueType.PRODUCT : CatalogueType.SERVICE,
			imageUrl: item.imageUrls[0] || undefined,
			isVatApplicable: true, // Can be dynamic
		}
		return basketItem
	})
}
