export enum PermalinkResponseCodes {
	/**
	 * All is good
	 */
	Perm_00 = "Perm_00",
	/**
	 * Record not found
	 */
	Perm_01 = "Perm_01",
	/**
	 * Record already exists
	 */
	Perm_02 = "Perm_02",
	/**
	 * MCC does not allow you to use this feature
	 */
	Perm_03 = "Perm_03",
	/**
	 * Merchant profile is disabled
	 */
	Perm_04 = "Perm_04",
	/**
	 * Over 80k limit
	 */
	Perm_05 = "Perm_05",
	/**
	 * Update permalink error
	 */
	Perm_06 = "Perm_06",
	/**
	 * Create permalink order error
	 */
	Perm_07 = "Perm_07",
	/**
	 * Permalink no longer active
	 */
	Perm_08 = "Perm_08",
	/**
	 * Get permalink by history error
	 */
	Perm_09 = "Perm_09",
	/**
	 * Invalid permalink order amount
	 */
	Perm_10 = "Perm_10",
	/**
	 * Tip amount provided but not required in request
	 */
	Perm_11 = "Perm_11",
	/**
	 * Customer delivery info provided but not required in request
	 */
	Perm_12 = "Perm_12",
	/**
	 * Customer reference provided but not required in request
	 */
	Perm_13 = "Perm_13",
	/**
	 * Invalid order amount for existing order
	 */
	Perm_14 = "Perm_14",
	/**
	 * Error updating the permalink order
	 */
	Perm_15 = "Perm_15",
	/**
	 * ESN Already exists for different owner
	 */
	Perm_16 = "Perm_16",
	/**
	 * ESN is unique with no owner
	 */
	Perm_17 = "Perm_17",
	/**
	 * Create permalink error
	 */
	Perm_18 = "Perm_18",

	/**
	 * You have reached the maximum limit of ESN updates
	 */
	Perm_22 = "Perm_22",
}

export enum Routes {
	/**
	 * Choose and save the webstore name
	 */
	Setup_Name = "/store_setup/name",
	/**
	 * Choose the products and services to sell
	 */
	Setup_Catalogue = "/store_setup/catalogue",

	/**
	 * All the Manage paths need to be prepended with the saved store name.
	 */
	// TO DO: confirm this route
	Manage_Catalogue = "/manage/catalogue",

	Manage_Builder = "/manage/builder",
}

export enum PermalinkType {
	PaymentPage = "PaymentPage",
}

export enum BuilderComponent {
	About = "about",
	Address = "address",
	Hero_Content = "hero_content",
	Hero_Image = "hero_image",
	None = "none",
}

/**
 * Keep track of the configuration progress of web store
 */
export enum WebstoreConfigurationStep {
	NAME_SELECTION = "WEBSTORE_NAME_SELECTION",
	ITEM_SELECTION = "CATALOGUE_ITEM_SELECTION",
	AESTHETIC_SELECTION = "AESTHETIC_SELECTION",
}

export enum WebstoreTheme {
	DefaultTheme = "DEFAULT_THEME",
	Blue = "BLUE",
	Teal = "TEAL",
	Orange = "ORANGE",
	Green = "GREEN",
	Purple = "PURPLE",
	DarkTheme = "DARK_THEME",
}

export enum WebstoreFulfilment {
	PICKUP = "PICKUP",
	DELIVERY = "DELIVERY",
	PICKUP_OR_DELIVERY = "PICKUP_OR_DELIVERY",
}

export enum WebstoreConfigurationFont {
	TIMES_NEW_ROMAN = "TIMES_NEW_ROMAN",
}

export enum WebstoreBusinessDay {
	Mon = "MON",
	Tues = "TUES",
	Wed = "WED",
	Thurs = "THUR",
	Fri = "FRI",
	Sat = "SAT",
	Sun = "SUN",
}

export enum ProfileStatus {
	Enabled = "ENABLED",
	Disabled = "DISABLED",
	Pending = "PENDING",
}

export enum PermalinkStatus {
	Active = "Active",
	Inactive = "Inactive",
}

export enum SideMenuItem {
	Setup_Guide = "Setup_Guide",
	Settings = "Settings",
	None = "None",
}

export enum PreviewType {
	Desktop = "Desktop",
	Mobile = "Mobile",
}

export enum WebstoreDocumentKey {
	PAIA_MANUAL = "paia_manual",
	PRIVACY_POLICY = "privacy_policy",
	RETURNS_REFUNDS_POLICY = "returns_refunds_policy",
	DELIVERY_POLICY = "delivery_policy",
	ACCEPT_TERMS = "accepted_tncs",
}
