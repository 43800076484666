import { WebstoreConfigurationStep, WebstoreFulfilment, WebstoreTheme } from "@/enums"
import { EditItem, SettingsUIState } from "@/state/builderMenu"
import { businessLogoExistsAtom, cardsCacheAtom } from "@/state/catalogue"
import { viewedStorePoliciesAtom } from "@/state/webstorePolicyReview"
import { BusinessHoursSlot, Contact, Hero, SocialPages } from "@/types"
import {
	aboutUsSchema,
	bannerTextSchema,
	deliveryFeeSchema,
	pickupInstructionsSchema,
	socialMediaDetailsSchema,
	storeDetailsSchema,
} from "@/utils/validationSchemas"
import { atom } from "jotai"
import { atomWithFormControls, atomWithValidate } from "jotai-form"

/**
 * Site builder form state for current step
 */
export const currentStepAtom = atomWithValidate<WebstoreConfigurationStep>(WebstoreConfigurationStep.AESTHETIC_SELECTION, {
	validate: async (v) => v,
})

/**
 * Site builder form state for Banner text
 */
export const bannerTextAtom = atomWithValidate<Hero>(
	{
		header: "",
		subheading: "",
		imageUrl: "",
	},
	{
		validate: async (v) => {
			await bannerTextSchema.validate(v)
			return v
		},
	},
)

/**
 * Site builder form state for About us
 */
export const aboutUsAtom = atomWithValidate("", {
	validate: async (v) => {
		await aboutUsSchema.validate(v)
		return v
	},
})

/**
 * Site builder form state for Store details
 */
export const storeDetailsAtom = atomWithValidate<Contact>(
	{
		address: "",
		email: "",
		phone: "",
		whatsapp: "",
	},
	{
		validate: async (v) => {
			await storeDetailsSchema.validate(v)
			return v
		},
	},
)

/**
 * Site builder form state for Social media details
 */
export const socialMediaDetailsAtom = atomWithValidate<SocialPages>(
	{
		facebook: "",
		instagram: "",
		tiktok: "",
	},
	{
		validate: async (v) => {
			await socialMediaDetailsSchema.validate(v)
			return v
		},
	},
)

/**
 * Site builder form state for Business hours
 */
export const businessHoursAtom = atomWithValidate<BusinessHoursSlot[]>([], {
	validate: async (v) => v,
})

/**
 * Site builder form state for color scheme
 */
export const colorSchemeAtom = atomWithValidate<WebstoreTheme>(WebstoreTheme.DefaultTheme, {
	validate: async (v) => v,
})

/**
 * Consolidated form state for site builder
 */
export const builderValuesAtom = atomWithFormControls({
	currentStep: currentStepAtom,
	colorScheme: colorSchemeAtom,
	bannerText: bannerTextAtom,
	aboutUs: aboutUsAtom,
	socialMediaDetails: socialMediaDetailsAtom,
	storeDetails: storeDetailsAtom,
	businessHours: businessHoursAtom,
})

type ValidationValue = "complete" | "incomplete" | "invalid"

const hasSomeValue = (values: (object | string | undefined)[]) => (values.some((value) => value) ? "complete" : "incomplete")

/**
 * Site builder state for validation
 */
export const builderValidationValuesAtom = atom<Record<EditItem, ValidationValue>>((get) => {
	const businessLogoExists = get(businessLogoExistsAtom)
	const cardsCache = get(cardsCacheAtom)
	const { fieldErrors, values } = get(builderValuesAtom)
	const {
		values: { fulfilment },
	} = get(fulfilmentValuesAtom)
	const { acceptedAt } = get(viewedStorePoliciesAtom)

	const hero = values.bannerText as Hero
	const socials = values.socialMediaDetails as SocialPages
	const contact = values.storeDetails as Contact

	let storeDetailsRequiredFieldsCompleted =
		!contact.phone || !contact.email
			? "incomplete"
			: hasSomeValue([contact.address, contact.phone, contact.whatsapp, contact.email])

	const deliveryState =
		(fulfilment === WebstoreFulfilment.PICKUP || fulfilment === WebstoreFulfilment.PICKUP_OR_DELIVERY) && !contact.address
			? "incomplete"
			: "complete"
	const acceptedAtState = !acceptedAt ? "incomplete" : "complete"

	return {
		[EditItem.CatalogueItems]: cardsCache.length ? "complete" : "incomplete",
		[EditItem.HeroContent]: fieldErrors.bannerText ? "invalid" : hasSomeValue([hero.header, hero.subheading]),
		[EditItem.AboutUs]: fieldErrors.aboutUs ? "invalid" : hasSomeValue([values.aboutUs]),
		[EditItem.Socials]: fieldErrors.socialMediaDetails
			? "invalid"
			: hasSomeValue([socials.facebook, socials.instagram, socials.tiktok]),
		[EditItem.StoreDetails]: fieldErrors.storeDetails ? "invalid" : storeDetailsRequiredFieldsCompleted,
		[EditItem.ConfigureStoreSettings]: deliveryState === "complete" && acceptedAtState === "complete" ? "complete" : "incomplete",
		[EditItem.Logo]: businessLogoExists ? "complete" : "incomplete",
	} as Record<EditItem, ValidationValue>
})

/**
 * Site builder form state for fulfilment method
 */
export const fulfilmentAtom = atomWithValidate<WebstoreFulfilment | null>(null, {
	validate: async (v) => v,
})

/**
 * Site builder form state for Pickup instructions
 */
export const pickupInstructionsAtom = atomWithValidate("", {
	validate: async (v) => {
		await pickupInstructionsSchema.validate(v)
		return v
	},
})

/**
 * Site builder form state for Delivery fee
 */
export const deliveryFeeAtom = atomWithValidate("", {
	validate: async (v) => {
		await deliveryFeeSchema.validate(v)
		return v
	},
})

/**
 * Consolidated form state for pickup and delivery
 */
export const fulfilmentValuesAtom = atomWithFormControls({
	fulfilment: fulfilmentAtom,
	pickupInstructions: pickupInstructionsAtom,
	deliveryFee: deliveryFeeAtom,
})

/**
 * Site builder state for settings validation
 */
export const settingsValidationValuesAtom = atom<Record<SettingsUIState, ValidationValue>>((get) => {
	const { values: builderValues } = get(builderValuesAtom)
	const {
		values: { fulfilment },
	} = get(fulfilmentValuesAtom)
	const { acceptedAt } = get(viewedStorePoliciesAtom)

	const address = (builderValues.storeDetails as Contact).address
	const deliveryState =
		(fulfilment === WebstoreFulfilment.PICKUP || fulfilment === WebstoreFulfilment.PICKUP_OR_DELIVERY) && !address
			? "incomplete"
			: "complete"

	return {
		[SettingsUIState.PickupAndDelivery]: deliveryState,
		[SettingsUIState.StorePolicies]: !acceptedAt ? "incomplete" : "complete",
	} as Record<SettingsUIState, ValidationValue>
})
